export const statusOptions = [
  {
    label: `All`,
    value: `All`,
  },
  {
    label: `Applied`,
    value: `APPLIED`,
    color: `rgba(249, 171, 6, 0.12)`,
  },
  {
    label: `In Review`,
    value: `IN_REVIEW`,
  },
  {
    label: `Technical Assessment`,
    value: `TECHNICAL_ASSESSMENT`,
  },
  {
    label: `Offer Extend`,
    value: `OFFER_EXTEND`,
  },
  {
    label: `On Hold`,
    value: `ON_HOLD`,
  },
  {
    label: `Hired`,
    value: `HIRED`,
    color: `rgba(77, 198, 64, 0.12)`,
  },
  {
    label: `Rejected`,
    value: `REJECTED`,
  },
  {
    label: `Withdrawn`,
    value: `WITHDRAWN`,
  },
  {
    label: `Not Selected`,
    value: `NOT_SELECTED`,
  },
]

export const APPLICANT_TABLE_HEAD_DATA = [
  `NAME`,
  ``,
  `DATE`,
  `EXPERIENCE`,
  `PHONE`,
  `EMAIL`,
  // `RESUME`,
  `STATUS`,
]

export const APPLICANT_DETAILS_DROPDOWN_OPTIONS = [
  {
    label: `Applied`,
    value: `APPLIED`,
    bgcolor: `rgba(249, 171, 6, 0.12)`,
    color: `rgba(249, 171, 6)`,
  },
  {
    label: `In Review`,
    value: `IN_REVIEW`,
    bgcolor: `rgba(249, 171, 6, 0.12)`,
    color: `rgba(249, 171, 6)`,
  },
  {
    label: `Technical Assessment`,
    value: `TECHNICAL_ASSESSMENT`,
    bgcolor: `rgba(249, 171, 6, 0.12)`,
    color: `rgba(249, 171, 6)`,
  },
  {
    label: `Offer Extend`,
    value: `OFFER_EXTEND`,
    bgcolor: `rgba(77, 198, 64, 0.12)`,
    color: `rgba(77, 198, 64)`,
  },
  {
    label: `On Hold`,
    value: `ON_HOLD`,
    bgcolor: `rgba(249, 171, 6, 0.12)`,
    color: `rgba(249, 171, 6)`,
  },
  {
    label: `Hired`,
    value: `HIRED`,
    bgcolor: `rgba(77, 198, 64, 0.12)`,
    color: `rgba(77, 198, 64)`,
  },
  {
    label: `Rejected`,
    value: `REJECTED`,
    bgcolor: `rgba(255,72,94,0.12)`,
    color: `rgb(255,72,94)`,
  },
  {
    label: `Withdrawn`,
    value: `WITHDRAWN`,
    bgcolor: `rgba(255,72,94,0.12)`,
    color: `rgb(255,72,94)`,
  },
  {
    label: `Not Selected`,
    value: `NOT_SELECTED`,
    bgcolor: `rgba(255,72,94,0.12)`,
    color: `rgb(255,72,94)`,
  },
]

export const APPLICANT_STATS_DATA = [
  {
    title: `Phone`,
    key: `phone`,
  },
  {
    title: `Email`,
    key: `email`,
  },
  {
    title: `Date Applied`,
    key: `date`,
  },
  {
    title: `Qualification`,
    key: `education`,
  },
  {
    title: `Experience`,
    key: `experience`,
  },
  {
    title: `Resume`,
    key: `resume`,
  },
  {
    title: `Available in US time zone`,
    key: `availableToWorkInUSTimeZone`,
  },

  {
    title: `Address`,
    key: `address`,
  },
]

export const INVOICE_STATUS_OPTIONS = [
  {
    label: `Paid`,
    value: `PAID`,
  },
  {
    label: `Unpaid`,
    value: `UNPAID`,
  },
  {
    label: `Cancelled`,
    value: `CANCELLED`,
  },
]

export const JOB_TABLE_HEADER_TITLES = [
  `created by`,
  `job title`,
  `created at`,
  `tm approval`,
  `hr approval`,
  `job status`,
]

//? Job Types Options for dropdown

export const JOB_TYPE_OPTIONS = [
  {
    _id: `ON-SITE`,
    name: `On-site`,
  },
  {
    _id: `HYBRID`,
    name: `Hybrid`,
  },
  {
    _id: `REMOTE`,
    name: `Remote`,
  },
]

export const TEAMS_TABLE_HEADERS = [
  `SR.#`,
  `Team Name`,
  `Team Memebers`,
  `team lead`,
  `project manager`,
  `actions`,
]

// ? Assessmnet status options

export const ASSESSMENT_STATUS_OPT = [
  {
    label: `Pending`,
    value: `PENDING`,
  },
  {
    label: `Completed`,
    value: `COMPLETED`,
  },
]

//? Job status options
export const JOB_STATUS_OPT = [
  {
    label: `Pending`,
    value: `PENDING`,
  },
  {
    label: `On Hold`,
    value: `ON_HOLD`,
  },
  {
    label: `Open`,
    value: `OPEN`,
  },
  {
    label: `Close`,
    value: `CLOSE`,
  },
]
//? Job status approval options
export const JOB_STATUS_APPROVAL_OPT = [
  {
    label: `Pending`,
    value: `PENDING`,
  },
  {
    label: `On Hold`,
    value: `ON_HOLD`,
  },

  {
    label: `Decline`,
    value: `DECLINE`,
  },
  {
    label: `Approved`,
    value: `APPROVED`,
  },
]

//? Job Approval status filter options
export const JOB_APPROVAL_STATUS_FILTER_OPT = [
  {
    label: `All`,
    _id: `ALL`,
  },
  {
    label: `Pending`,
    value: `PENDING`,
    _id: `PENDING`,
  },
  {
    label: `On Hold`,
    value: `ON_HOLD`,
    _id: `ON_HOLD`,
  },
  {
    label: `Close`,
    value: `CLOSE`,
    _id: `CLOSE`,
  },
  {
    label: `Decline`,
    value: `DECLINE`,
    _id: `DECLINE`,
  },
  {
    label: `Approved`,
    value: `APPROVED`,
    _id: `APPROVED`,
  },
]

//? Job status filter options
export const JOB_STATUS_FILTER_OPT = [
  {
    label: `All`,
    _id: `ALL`,
  },
  {
    label: `Pending`,
    value: `PENDING`,
    _id: `PENDING`,
  },
  {
    label: `On Hold`,
    value: `ON_HOLD`,
    _id: `ON_HOLD`,
  },
  {
    label: `Close`,
    value: `CLOSE`,
    _id: `CLOSE`,
  },
  {
    label: `Open`,
    value: `OPEN`,
    _id: `OPEN`,
  },
]

//? Job position filled by options
export const JOB_FILLED_BY_OPT = [
  {
    name: `Internally`,
    _id: `INTERNALLY`,
  },
  {
    name: `Externally`,
    _id: `EXTERNALLY`,
  },
]

//? Applicant Status Options

export const APPLICANT_STATUS_OPTS = [
  {
    name: `ALL`,
    _id: `ALL`,
  },
  {
    name: `Initial Screening`,
    _id: `INITIAL_SCREENING`,
  },
  {
    name: `Technical Interview`,
    _id: `TECHNICAL_INTERVIEW`,
  },
  {
    name: `Final Technical Interview`,
    _id: `FINAL_TECHNICAL_INTERVIEW`,
  },
  {
    name: `Meeting With HR`,
    _id: `MEETING_WITH_HR`,
  },
  {
    name: `Negotiations`,
    _id: `NEGOTIATIONS`,
  },
  {
    name: `Offer Letter Accepted`,
    _id: `OFFER_LETTER_ACCEPTED`,
  },
  {
    name: `Offer Letter Rejected`,
    _id: `OFFER_LETTER_REJECTED`,
  },
  {
    name: `Not Eligible`,
    _id: `NOT_ELIGIBLE`,
  },
  {
    name: `Not Responsive`,
    _id: `NOT_RESPONSIVE`,
  },
  {
    name: `Offer Letter Sent`,
    _id: `OFFER_LETTER_SENT`,
  },
  {
    name: `Hired`,
    _id: `HIRED`,
  },
]

//? Applicants table header options
export const APPLICANTS_TABLE_HEADERS = [
  `sr#`,
  `applicant's name`,
  `date applied`,
  `job`,
  `status`,
  `actions`,
]

//? Applicant Assesment Phases
export const APPLICANTS_ASSESSMENT_PHASE_OPTS = [
  {
    name: `Initial Screening`,
    _id: `INITIAL_SCREENING`,
  },
  {
    name: `Skill Assessment`,
    _id: `SKILL_ASSESSMENT`,
  },
  {
    name: `Technical Interview`,
    _id: `TECHNICAL_INTERVIEW`,
  },
  {
    name: `Final Technical Interview`,
    _id: `FINAL_TECHNICAL_INTERVIEW`,
  },
]

//? Applicant Initail Screening Status options
export const APPLICANT_INITIAL_SCREENING_STATUS_OPTS = [
  {
    name: `Pending`,
    _id: `PENDING`,
  },
  {
    name: `Completed`,
    _id: `COMPLETED`,
  },
  {
    name: `Not Responding`,
    _id: `NOT_RESPOND`,
  },
]

//? Applicant Skill Assessmnet Status options
export const APPLICANT_SKILL_ASSESSMENT_STATUS_OPTS = [
  {
    name: `Pending`,
    _id: `PENDING`,
  },
  {
    name: `Pass`,
    _id: `PASS`,
  },
  {
    name: `Fail`,
    _id: `FAIL`,
  },
]

//? Applicant Job Interview Status options
export const APPLICANT_INTERVIEW_STATUS_OPTS = [
  {
    name: `Scheduled`,
    _id: `SCHEDULED`,
  },
  {
    name: `Rescheduled`,
    _id: `RE_SCHEDULED`,
  },
  {
    name: `Recommende`,
    _id: `RECOMMENDED`,
  },
  {
    name: `Not Recommended`,
    _id: `NOT_RECOMMENDED`,
  },
  {
    name: `Eligible`,
    _id: `ELIGIBLE`,
  },
  {
    name: `Not Eligible`,
    _id: `NOT_ELIGIBLE`,
  },
  {
    name: `Not Responsive`,
    _id: `NOT_RESPONSIVE`,
  },
  {
    name: `On Hold`,
    _id: `ON_HOLD`,
  },
  {
    name: `Rejected`,
    _id: `REJECTED`,
  },
  {
    name: `Canceled`,
    _id: `CANCELED`,
  },
]

//? Applicant HR Actions Status options
export const APPLICANT_HR_ACTIONS_STATUS_OPTS = [
  {
    name: `Meeting With HR`,
    _id: `MEETING_WITH_HR`,
  },
  {
    name: `Negotiations`,
    _id: `NEGOTIATIONS`,
  },
  {
    name: `Offer Letter Sent`,
    _id: `OFFER_LETTER_SENT`,
  },
  {
    name: `Offer Letter Accepted`,
    _id: `OFFER_LETTER_ACCEPTED`,
  },
  {
    name: `Offer Letter Rejected`,
    _id: `OFFER_LETTER_REJECTED`,
  },
  {
    name: `Not Eligible`,
    _id: `NOT_ELIGIBLE`,
  },
  {
    name: `Not Responsive`,
    _id: `NOT_RESPONSIVE`,
  },
  {
    name: `On Hold`,
    _id: `ON_HOLD`,
  },
  {
    name: `Rejected`,
    _id: `REJECTED`,
  },
  {
    name: `Hired`,
    _id: `HIRED`,
  },
]

//? ALL TAX TABLE HEADERS
export const ALL_TAX_DETAILS_HEADERS = [
  `users`,
  `total base salary`,
  `total tax `,
  `paid tax`,
  `unpaid tax`,
]

//$ ================================================================================ $//
//$                          FISCAL YEAR TAX TABLE HEADERS
//$ ================================================================================ $//

export const FISCAL_YEAR_TAX_DETAILS_HEADERS = [
  `users`,
  `total base salary`,
  `total tax `,
  `paid tax`,
  `unpaid tax`,
  `status`,
]

//$ ================================================================================ $//
//$                           SINGLE USER TAX TABLE HEADERS
//$ ================================================================================ $//

export const SINGLE_USER_TAX_DETAILS_HEADERS = [
  `month`,
  `monthly salary `,
  `monthly tax `,
  `status`,
]

//$ ================================================================================ $//
//$                                TAX STATUS OPTIONS
//$ ================================================================================ $//

export const TAX_STATUS_OPTS = [
  {
    label: `Paid`,
    value: `PAID`,
  },
  {
    label: `Unpaid`,
    value: `UNPAID`,
  },
]

//$ ================================================================================ $//
//$                              CONTRACTS TABLE HEADERS
//$ ================================================================================ $//

export const CONTRACTS_TABLE_HEADERS = [
  `Start date`,
  `end date`,
  `status`,
  //TODO `actions`,
]

//$ ================================================================================ $//
//$                                 GENDER OPTIONS
//$ ================================================================================ $//

export const GENDER_OPTIONS = [
  {
    _id: `MALE`,
    name: `Male`,
  },
  {
    _id: `FEMALE`,
    name: `Female`,
  },
]

//$ ================================================================================ $//
//$                                 MARTIAL STATUS OPTIONS
//$ ================================================================================ $//

export const MARTIAL_STATUS_OPTIONS = [
  {
    _id: `SINGLE`,
    name: `Single`,
  },
  {
    _id: `MARRIED`,
    name: `Married`,
  },
  {
    _id: `WIDOW`,
    name: `Widow`,
  },
  {
    _id: `DIVORCED`,
    name: `Divorced`,
  },
]
//$ ================================================================================ $//
//$                                 BLOOD GROUP OPTIONS
//$ ================================================================================ $//

export const BLOOD_GROUP_OPTIONS = [
  {
    _id: `A+`,
    name: `A+`,
  },
  {
    _id: `A-`,
    name: `A-`,
  },
  {
    _id: `B+`,
    name: `B+`,
  },
  {
    _id: `B-`,
    name: `B-`,
  },
  {
    _id: `AB+`,
    name: `AB+`,
  },
  {
    _id: `AB-`,
    name: `AB-`,
  },
  {
    _id: `O+`,
    name: `O+`,
  },
  {
    _id: `O-`,
    name: `O-`,
  },
]

//$ ================================================================================ $//
//$                                  USER STATUS OPTIONS
//$ ================================================================================ $//

export const USER_ACTIVE_STATUS_OPTIONS = [
  {
    _id: `ACTIVE`,
    name: `Active`,
  },
  {
    _id: `INCTIVE`,
    name: `Inactive`,
  },
]
//$ ================================================================================ $//
//$                                  SYSTEM ROLE OPTIONS
//$ ================================================================================ $//

export const SYSTEM_ROLE_OPTIONS = [
  {
    _id: `CEO`,
    name: `CEO`,
  },
  {
    _id: `CTO`,
    name: `CTO`,
  },
  {
    _id: `HRM`,
    name: `HRM`,
  },
  {
    _id: `EMPLOYEE`,
    name: `EMPLOYEE`,
  },
]

//$ ================================================================================ $//
//$                               EMPLOYMENT STATUS OPTIONS
//$ ================================================================================ $//

export const EMPLOYMENT_STATUS_OPTIONS = [
  {
    _id: `INTERN`,
    name: `Intern`,
  },
  {
    _id: `EMPLOYED`,
    name: `Employed`,
  },
  {
    _id: `NOTICE_PERIOD`,
    name: `Notice Period`,
  },
  {
    _id: `PROBATION_PERIOD`,
    name: `Probation Period`,
  },
  {
    _id: `TERMINATED`,
    name: `Terminated`,
  },
  {
    _id: `STAFF`,
    name: `Staff`,
  },
  {
    _id: `RESIGNED`,
    name: `Resigned`,
  },
]

//$ ================================================================================ $//
//$                              JOB TYPE OPTIONS
//$ ================================================================================ $//

export const JOB_TYPE_OPTS = [
  {
    _id: `FULL_TIME`,
    name: `Full Time`,
  },
  {
    _id: `PART_TIME`,
    name: `Part Type`,
  },
]

//$ ================================================================================ $//
//$                              JOB TYPE OPTIONS
//$ ================================================================================ $//

export const LOCATION_TYPE_OPTS = [
  {
    _id: `ON-SITE`,
    name: `On-Site`,
  },
  {
    _id: `WORK_FROM_HOME`,
    name: `Work Form Home`,
  },
]

//$ ================================================================================ $//
//$                              JOB TYPE OPTIONS
//$ ================================================================================ $//

export const SHIFT_OPTIONS = [
  {
    _id: `65f000e931364cc9610d8eee`,
    name: `Evening`,
  },
  {
    _id: `64cb3211d11ec6a226b2b122`,
    name: `Morning`,
  },
]

//$ ================================================================================ $//
//$                              ALLOWANCES TYPE OPTIONS
//$ ================================================================================ $//

export const ALLOWANCES_OPTIONS = [
  {
    _id: `HEALTH`,
    name: `Health`,
  },
  {
    _id: `TRAVEL`,
    name: `Travel`,
  },
  {
    _id: `RESIDENCE`,
    name: `Residence`,
  },
  {
    _id: `GYM`,
    name: `Gym`,
  },
  {
    _id: `INTERNET`,
    name: `Internet`,
  },
  {
    _id: `MOBILE`,
    name: `Mobile`,
  },
]

//$ ================================================================================ $//
//$                              ALLOWANCES FREQUENCY
//$ ================================================================================ $//

export const ALLOWANCES_FREQUENCY_OPTIONS = [
  {
    _id: `ANNUAL`,
    name: `Annual`,
  },
  {
    _id: `MONTHLY`,
    name: `Monthly`,
  },
]
