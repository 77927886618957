import { combineReducers } from 'redux'
import { addEmployeeReducer } from './slices/user'
import { displayEmployeeReducer } from './slices/user'
import { filterPayrollReducer } from './slices/user'
import { filterRequestsReducer } from './slices/user'
import { userReducer } from './slices/user'
import { requestChangeReducer } from './slices/user'
import { requestCountReducer } from './slices/user'
import { persistReducer } from 'redux-persist'
import { filterAttendanceReducer } from './slices/user'
import { dailyAttendanceDateSliderReducer } from './slices/user'
import { monthlyAttendanceDateSliderReducer } from './slices/user'
import { monthlyIndividualReducer } from './slices/user'
import { drawerReducer } from './slices/user/Drawer/index'
import { colorsReducer } from './slices/user'
import { quotationActivationReducer } from './slices/user'
import { configurationReducer } from './slices/user'
import { selectedItemReducer } from './slices/user/Drawer/index'
import { expenseFilterReducer } from './slices/user/Expenses/index'
import storage from './presistStorage'
import { breadCrumbs } from './slices/BreadCrumbs'
import { account } from './slices/Accounts'
import { attendance } from './slices/IndividualAttandance'
import { assessment } from './slices/Assessment/index'
import { assessmentId } from './slices/Assessment/AssessmentId'

const persistConfig = {
  key: `root`, // Root key for the persisted state in the storage
  storage,
  whitelist: [
    `user`,
    `heading`,
    `drawer`,
    `colors`,
    `quotation`,
    `configuration`,
    `selectedItem`,
    `assessment`,
    `assessmentId`,
  ], // List of reducers to persist
}

const rootReducer = combineReducers({
  addEmployee: addEmployeeReducer,
  displayEmployee: displayEmployeeReducer,
  filterPayroll: filterPayrollReducer,
  filterRequests: filterRequestsReducer,
  user: userReducer,
  requestChange: requestChangeReducer,
  filterAttendance: filterAttendanceReducer,
  dailySlider: dailyAttendanceDateSliderReducer,
  monthlySlider: monthlyAttendanceDateSliderReducer,
  monthlyIndividual: monthlyIndividualReducer,
  requestCount: requestCountReducer,
  drawer: drawerReducer,
  colors: colorsReducer,
  quotation: quotationActivationReducer,
  configuration: configurationReducer,
  selectedItem: selectedItemReducer,
  expenseFilter: expenseFilterReducer,
  breadCrumbs,
  account,
  attendance,
  assessment,
  assessmentId,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)
export { persistedReducer }
