import {
  Box,
  Button,
  DialogContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core'
import { Dialog, DialogTitle, Divider } from '@mui/material'
import React, { useContext, useRef } from 'react'
import { useState } from 'react'
import PrimaryButton from 'app/Components/Common/PrimaryButton'
import SecondaryButton from 'app/Components/Common/SecondaryButton'
import { useSelector } from 'react-redux'
import { useMutation } from 'react-query'
import { baseUrl } from 'app/Utils/Config'
import api from 'app/AxiosInstance'
import { SnackbarContext } from 'app/Components/SnackbarProvider'
import { useEffect } from 'react'
import ReactInputMask from 'react-input-mask'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import moment from 'moment'
import Image from 'mui-image'
import crossIcon from '../../../../Assets/cross_ic.svg'
import uploadIcon from '../../../../Assets/uploadDocument_ic.svg'
import file from '../../../../Assets/file_ic.svg'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'

const UpdateTransaction = ({
  onClose,
  open,
  refetchTransaction,
  transactionData,
  fundsData,
  singleFund,
}) => {
  const { fundsId } = useParams()

  const currentFund = fundsData?.find((fund) => fund?._id === fundsId)

  const loggedInUserId = localStorage.getItem(`loggedInUserId`)
  const loggedInUserName = localStorage.getItem(`loggedInEmployeeName`)

  const colors = useSelector((state) => state?.colors)
  const openSnackbar = useContext(SnackbarContext)

  let hiddenFileInput = useRef(null)
  const formData = new FormData()

  const [mediaName, setMediaName] = useState(``)

  const [fund, setFund] = useState({
    paymentMode: ``,
    date: new Date(),
    amount: ``,
    receipt: ``,
    description: ``,
    allocatedTo: ``,
  })
  useEffect(() => {
    if (transactionData) {
      setFund((prevState) => ({
        ...prevState,
        paymentMode: transactionData?.paymentMode || ``,
        date: transactionData?.date || new Date(),
        amount: transactionData?.amount || ``,
        receipt: transactionData?.receipt || ``,
        description: transactionData?.description || ``,
        allocatedTo: singleFund?._id || ``,
      }))
    }
    setMediaName(transactionData?.receipt?.key)
  }, [])

  const [errors, setErrors] = useState({
    formSubmit: false,
    date: false,
    amount: false,
    paymentMode: false,
    receipt: false,
    description: ``,
    allocatedTo: false,
  })

  const { mutate: addTransaction, isLoading } = useMutation(
    async (payload) =>
      await api
        .patch(`${baseUrl}/funds/${fundsId}/updateTransaction/${transactionData?._id}`, {
          ...payload,
        })
        .then((res) => {
          if (res?.data) {
            onClose()
            openSnackbar(`Transaction updated successfully`, `success`)
            res?.data
          }
        }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: () => {
        refetchTransaction()
      },
      onError: (error) => {
        openSnackbar(
          error?.response
            ? error?.response?.data?.message
            : error?.message
            ? error?.message
            : `Something went wrong`,
          `error`,
        )
      },
    },
  )

  const validateForm = () => {
    if (!fund?.paymentMode?.length) {
      setErrors((prevState) => ({
        ...prevState,
        paymentMode: true,
      }))
    } else {
      setErrors((prevState) => ({
        ...prevState,
        paymentMode: false,
      }))
    }
    if (!fund?.amount) {
      setErrors((prevState) => ({
        ...prevState,
        amount: true,
      }))
    } else {
      setErrors((prevState) => ({
        ...prevState,
        amount: false,
      }))
    }
    if (!fund?.date) {
      setErrors((prevState) => ({
        ...prevState,
        date: true,
      }))
    } else {
      setErrors((prevState) => ({
        ...prevState,
        date: false,
      }))
    }
    if (!fund?.description?.length) {
      setErrors((prevState) => ({
        ...prevState,
        description: true,
      }))
    } else {
      setErrors((prevState) => ({
        ...prevState,
        description: false,
      }))
    }

    if (
      fund?.paymentMode &&
      (fund?.paymentMode === `BANK_TRANSFER` || fund?.paymentMode === `JAZZ_CASH`)
    ) {
      if (fund?.receipt?.length <= 0) {
        setErrors((prevState) => ({
          ...prevState,
          receipt: true,
        }))
      } else {
        setErrors((prevState) => ({
          ...prevState,
          receipt: false,
        }))
      }
    } else if (fund?.paymentMode !== `BANK_TRANSFER` || fund?.paymentMode !== `JAZZ_CASH`) {
      setErrors((prevState) => ({
        ...prevState,
        receipt: false,
      }))
    }

    setErrors((prevState) => ({
      ...prevState,
      formSubmit: true,
    }))
  }

  useEffect(() => {
    if (
      errors?.formSubmit &&
      !errors?.amount &&
      !errors?.date &&
      !errors?.paymentMode &&
      !errors?.description &&
      !errors?.allocatedTo &&
      !errors?.receipt
    ) {
      addTransaction(handleAddTransaction())

      setErrors((prevState) => ({
        ...prevState,
        formSubmit: false,
      }))
    } else if (errors?.formSubmit) {
      openSnackbar(`Please fill out all required fields`, `error`)
    }
  }, [errors])

  const handleAddTransaction = () => {
    const data = {}

    if (transactionData?.amount !== fund?.amount) {
      data.amount = parseInt(fund?.amount)
    }
    if (transactionData?.date !== fund?.date) {
      data.date = moment(fund?.date).format(`YYYY-MM-DD`).toString()
    }
    if (transactionData?.paymentMode !== fund?.paymentMode) {
      data.paymentMode = fund?.paymentMode
    }
    if (transactionData?.description !== fund?.description) {
      data.description = fund?.description
    }

    if (fund?.receipt) {
      data.receipt = fund?.receipt
    }
    if (transactionData?.transactionBy !== loggedInUserId) {
      data.transactionBy = loggedInUserId
    }

    return data
  }

  const handleFundChange = (name, value) => {
    setFund((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const { mutate: getFileUrl, isLoading: fileUploading } = useMutation(async () => {
    try {
      const res = await api.post(`${baseUrl}/users/upload-multiple-documents`, formData, {
        headers: {
          'Content-Type': `multipart/form-data`,
        },
      })

      res?.data?.data?.map((attachment) => {
        setFund((prevFund) => ({
          ...prevFund,
          receipt: {
            url: attachment?.attachment,
            key: attachment?.key,
          },
        }))
        setMediaName(attachment?.fileName)
      })
    } catch (error) {
      openSnackbar(`Uploading failed`, `error`)
    }
  })

  const handleFileUpload = (files) => {
    formData.append(`documents`, files[0])

    getFileUrl()
    hiddenFileInput.current.value = null
  }

  const handleCrossPhoto = () => {
    setFund((prevFund) => ({
      ...prevFund,
      receipt: ``,
    }))
    setMediaName(``)
  }

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
      PaperProps={{
        sx: {
          height: `max-content`,
          borderRadius: `20px`,
        },
      }}
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{
          height: `64px`,
          width: `100%`,
        }}
      >
        <Box
          sx={{
            display: `flex`,
            flexDirection: `row`,
            justifyContent: `space-between`,
            alignItems: `center`,
            backgroundColor: `grey`,
            marginLeft: `1rem`,
            marginRight: `1rem`,
          }}
        >
          <Typography>Update Transaction</Typography>
          <Box
            sx={{
              width: `36px`,
              height: `36px`,
              backgroundColor: `white`,
              display: `flex`,
              justifyContent: `center`,
              alignItems: `center`,
            }}
          >
            <Button onClick={onClose}>
              <img src={crossIcon} alt="cross" />
            </Button>
          </Box>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <TextField
                id="outlined-basic"
                label="Transaction by *"
                variant="outlined"
                size="small"
                value={loggedInUserName}
                InputLabelProps={{
                  style: {
                    color: `rgba(40, 32, 61, 0.5)`,
                  },
                }}
                disabled
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '&:hover fieldset': {
                      border: `solid ${colors.appColor} 1px`,
                    },
                    '&.Mui-focused fieldset': {
                      border: `solid ${colors.appColor} 1px`,
                    },
                    '& fieldset': {
                      borderColor: `rgba(40, 32, 61, 0.25)`,
                      borderRadius: `10px`,
                    },
                  },
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl
              size="small"
              fullWidth
              disabled
              sx={{
                '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': {
                    border: errors?.allocatedTo
                      ? `solid #FF485E 1px`
                      : `solid ${colors.appColor} 1px`,
                  },
                  '&.Mui-focused fieldset': {
                    border: errors?.allocatedTo
                      ? `solid #FF485E 1px`
                      : `solid ${colors.appColor} 1px`,
                  },
                  '& fieldset': {
                    borderColor: errors?.allocatedTo ? `#FF485E` : `rgba(40, 32, 61, 0.25)`,
                    borderRadius: `10px`,
                  },
                },
                '& .MuiInputLabel-root': {
                  '&.Mui-focused': {
                    color: errors?.allocatedTo ? `#FF485E` : `rgba(40, 32, 61, 0.25)`,
                  },
                },
              }}
            >
              <InputLabel
                id="demo-simple-select-label"
                sx={{
                  color: errors?.allocatedTo ? `#FF485E` : `rgba(40, 32, 61, 0.5)`,
                }}
              >
                Allocated To *
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="allocatedTo"
                label="Allocated To *"
                value={currentFund?.allocatedTo?._id}
                onChange={(e) => handleFundChange(`allocatedTo`, e.target.value)}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      maxHeight: `300px`,
                    },
                  },
                }}
              >
                <MenuItem value={currentFund?.allocatedTo?._id}>
                  {currentFund?.allocatedTo?.name}
                </MenuItem>
                {/* //*TODO incase of revert changes {fundsData &&
                  fundsData?.map((user) => (
                    <MenuItem key={user?._id} value={user?._id}>
                      {`${user?.allocatedBy?.name} - ${user?.allocatedTo?.name}`}
                    </MenuItem>
                  ))} */}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl
              size="small"
              fullWidth
              sx={{
                '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': {
                    border: errors?.paymentMode
                      ? `solid #FF485E 1px`
                      : `solid ${colors.appColor} 1px`,
                  },
                  '&.Mui-focused fieldset': {
                    border: errors?.paymentMode
                      ? `solid #FF485E 1px`
                      : `solid ${colors.appColor} 1px`,
                  },
                  '& fieldset': {
                    borderColor: errors?.paymentMode ? `#FF485E` : `rgba(40, 32, 61, 0.25)`,
                    borderRadius: `10px`,
                  },
                },
                '& .MuiInputLabel-root': {
                  '&.Mui-focused': {
                    color: errors?.paymentMode ? `#FF485E` : `rgba(40, 32, 61, 0.25)`,
                  },
                },
              }}
            >
              <InputLabel
                id="demo-simple-select-label"
                sx={{
                  color: errors?.paymentMode ? `#FF485E` : `rgba(40, 32, 61, 0.5)`,
                }}
              >
                Payment Mode *
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="paymentMode"
                label="Payment Mode *"
                value={fund?.paymentMode}
                onChange={(e) => handleFundChange(`paymentMode`, e.target.value)}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      maxHeight: `300px`,
                    },
                  },
                }}
              >
                <MenuItem value="BANK_TRANSFER">Bank Transfer</MenuItem>
                <MenuItem value="JAZZ_CASH">Jazz Cash</MenuItem>
                <MenuItem value="CASH">Cash In Hand</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl
              sx={{
                display: `flex`,
                flexDirection: `row`,
                alignItems: `center`,
                justifyContent: `center`,
                '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': {
                    border: errors?.amount ? `solid #FF485E 1px` : `solid ${colors.appColor} 1px`,
                    borderLeft: `none`,
                  },
                  '&.Mui-focused fieldset': {
                    border: errors?.amount ? `solid #FF485E 1px` : `solid ${colors.appColor} 1px`,
                    borderLeft: `none`,
                  },
                  '& fieldset': {
                    borderLeft: `none`,
                    borderColor: errors?.amount ? `#FF485E` : `rgba(40, 32, 61, 0.25)`,
                    borderRadius: `10px`,
                    borderTopLeftRadius: `0px`,
                    borderBottomLeftRadius: `0px`,
                  },
                },
              }}
            >
              <Typography
                sx={{
                  backgroundColor: `rgba(40, 32, 61, 0.12)`,
                  padding: `10px`,
                  paddingTop: `11px`,

                  fontSize: `14px`,
                  fontWeight: 400,
                  lineHeight: `16.94px`,
                  borderRadius: `10px`,
                  borderTopRightRadius: `0px`,
                  borderBottomRightRadius: `0px`,
                  border: errors?.amount ? `solid #FF485E 1px` : `solid rgba(40, 32, 61, 0.12) 1px`,
                  borderRight: `none`,
                }}
              >
                Rs
              </Typography>
              <ReactInputMask
                fullWidth
                mask="9999999999999"
                maskChar=""
                value={fund?.amount}
                onChange={(e) => handleFundChange(`amount`, e.target.value)}
              >
                {(inputProps) => (
                  <TextField
                    id="outlined-basic"
                    label="Amount *"
                    name="amount"
                    variant="outlined"
                    size="small"
                    InputLabelProps={{
                      style: {
                        color: errors?.amount ? `#FF485E` : `rgba(40, 32, 61, 0.5)`,
                      },
                    }}
                    {...inputProps}
                  />
                )}
              </ReactInputMask>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <FormControl fullWidth>
                <DatePicker
                  inputFormat="YYYY-MM-DD"
                  label="Date *"
                  value={fund?.date}
                  onChange={(e) => {
                    if (!e) {
                      handleFundChange(`date`, new Date())
                    } else {
                      handleFundChange(`date`, new Date(e))
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      {...params}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '&:hover fieldset': {
                            border: `solid  ${colors.appColor} 1px`,
                          },
                          '&.Mui-focused fieldset': {
                            border: `solid ${colors.appColor} 1px`,
                          },
                          '& fieldset': {
                            borderColor: `rgba(40, 32, 61, 0.5)`,
                            borderRadius: `10px`,
                          },
                          '& svg': { color: `#19C9D1` },
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          color: `rgba(40, 32, 61, 0.5)`,
                        },
                      }}
                    />
                  )}
                />
              </FormControl>
            </LocalizationProvider>
          </Grid>

          <Grid item xs={12}>
            <FormControl
              fullWidth
              sx={{
                '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': {
                    border: errors?.description
                      ? `solid #FF485E 1px`
                      : `solid ${colors.appColor} 1px`,
                  },
                  '&.Mui-focused fieldset': {
                    border: errors?.description
                      ? `solid #FF485E 1px`
                      : `solid ${colors.appColor} 1px`,
                  },
                  '& fieldset': {
                    borderColor: errors?.description ? `#FF485E` : `rgba(40, 32, 61, 0.25)`,
                    borderRadius: `10px`,
                  },
                },
                '& .MuiInputLabel-root': {
                  '&.Mui-focused': {
                    color: errors?.description ? `#FF485E` : `rgba(40, 32, 61, 0.25)`,
                  },
                },
              }}
            >
              <TextField
                rows={4}
                multiline={true}
                id="outlined-basic"
                label="Description * "
                name="description"
                value={fund?.description}
                variant="outlined"
                size="small"
                onChange={(e) => handleFundChange(`description`, e?.target?.value)}
                InputLabelProps={{
                  style: {
                    color: errors?.description ? `#FF485E` : `rgba(40, 32, 61, 0.5)`,
                  },
                }}
              />
            </FormControl>
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              display: `flex`,
              justifyContent: `center`,
              alignItems: `center`,
              backgroundColor: errors?.receipt
                ? `rgba(255, 72, 94, 0.1)`
                : `rgba(25, 201, 209, 0.1)`,
              height: `50px`,
              marginTop: `20px`,
              borderRadius: `10px`,
              paddingY: `10px`,
              marginLeft: `10px`,
              gap: `10px`,
            }}
          >
            <Typography
              sx={{
                color: errors?.receipt
                  ? `#FF485E`
                  : `
    rgba(96, 57, 187, 1)`,
                fontWeight: 400,
                fontSize: `14px`,
                lineHeight: `16.94px`,
              }}
            >
              Upload Receipt
            </Typography>
            <Image
              src={uploadIcon}
              width={24}
              height={24}
              sx={{
                '&:hover': {
                  cursor: `pointer`,
                },
              }}
              onClick={() => hiddenFileInput.current.click()}
            />
            <input
              multiple
              id="image-picker"
              type="file"
              ref={hiddenFileInput}
              onChange={(e) => handleFileUpload(e?.target?.files)}
              style={{ display: `none` }}
              disabled={fileUploading}
              accept=".jpg,.jpeg,.png,.gif,.doc,.JPG,.JPEG,.PNG,.GIF"
            />
          </Grid>

          {mediaName && (
            <Grid item xs={12} sm={6} md={4}>
              <Box
                sx={{
                  display: `flex`,
                  flexDirection: `row`,
                  justifyContent: `space-between`,
                  alignItems: `center`,
                  backgroundColor: `rgba(25, 201, 209, 0.1)`,
                  borderRadius: `10px`,
                  padding: `10px`,
                }}
              >
                <img src={file} alt="file" />
                <Typography
                  noWrap
                  sx={{
                    fontFamily: `Inter`,
                    fontWeight: 400,
                    fontSize: `14px`,
                  }}
                >
                  {mediaName}
                </Typography>

                <Typography
                  sx={{
                    fontFamily: `Inter`,
                    fontWeight: 400,
                    fontSize: `14px`,
                    '&:hover': { cursor: `pointer` },
                  }}
                  onClick={() => handleCrossPhoto()}
                >
                  x
                </Typography>
              </Box>
            </Grid>
          )}
        </Grid>

        <Box
          sx={{
            display: `flex`,
            flexDirection: {
              xs: `column-reverse`,
              sm: `row-reverse`,
            },
            gap: `10px`,
            marginY: `10px`,
          }}
        >
          <PrimaryButton
            title={`${fileUploading ? `Uploading` : `Submit`}`}
            onClick={validateForm}
            isLoading={isLoading || fileUploading}
          />
          <SecondaryButton title="Cancel" onClick={onClose} />
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default UpdateTransaction
