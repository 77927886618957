import React, { Suspense } from 'react'
import store from 'app/Redux/store'
import { Provider, useSelector } from 'react-redux'
import { QueryClient, QueryClientProvider } from 'react-query'
import {
  Switch,
  Route,
  BrowserRouter,
} from 'react-router-dom/cjs/react-router-dom.min'
// import Login from 'app/Pages/Login'
import ProtectedRoutes from 'app/ProtectedRoutes'
import ProtectedLogin from 'app/ProtectedLogin'
import AllEmployees from 'app/Pages/Employee/AllEmployees'
import { CircularProgress, Box } from '@material-ui/core'

const Login = React.lazy(() => import(`./app/Pages/Login`))
const Payroll = React.lazy(() => import(`./app/Pages/Payroll`))
const AllAttendance = React.lazy(() => import(`./app/Pages/AllAttendance`))
const PayrollSlip = React.lazy(() =>
  import(`app/Components/Payroll/Payrollslip`),
)

const PayrollAnalytics = React.lazy(() =>
  import(`./app/Pages/PayrollAnalytics`),
)

const IndividualsAttendance = React.lazy(() =>
  import(`./app/Pages/IndividualAttendance`),
)
const AllUsers = React.lazy(() =>
  import(`./app/Components/Users/AddUser/AllUsers`),
)
const AddNewUser = React.lazy(() =>
  import(`./app/Components/Users/AddUser/AddNewUser`),
)

const CompleteDetail = React.lazy(() =>
  import(`./app/Components/Users/UserDetails/CompleteDetail`),
)

const AddSalary = React.lazy(() => import(`./app/Pages/Salary/AddSalary`))

const EmployeeDetail = React.lazy(() =>
  import(`./app/Pages/Employee/EmployeeDetail`),
)

//* Applicants Pages
const AllApplicants = React.lazy(() => import(`./app/Pages/Applicants`))
const ApplicantDetails = React.lazy(() =>
  import(`./app/Components/Applicants/ApplicantDetails`),
)

const Departments = React.lazy(() => import(`./app/Pages/Departments`))
const Teams = React.lazy(() => import(`./app/Pages/Teams`))
const Designation = React.lazy(() => import(`./app/Pages/Designation`))
const Positions = React.lazy(() => import(`./app/Pages/Positions`))
const CompanyProjects = React.lazy(() => import(`./app/Pages/Projects`))
const HalfDays = React.lazy(() => import(`./app/Pages/HalfDays`))
const Holidays = React.lazy(() => import(`app/Pages/Holidays`))
const Shifts = React.lazy(() => import(`./app/Pages/Shifts`))
const Configuration = React.lazy(() => import(`./app/Components/Configuration`))
const TaxDetails = React.lazy(() => import(`./app/Components/TaxDetails`))
const Quotations = React.lazy(() => import(`./app/Components/Quotations/index`))
const Dashboard = React.lazy(() => import(`./app/Pages/Dashboard`))
const Requests = React.lazy(() => import(`./app/Components/Requests/indexx`))

const TaxSlabs = React.lazy(() => import(`./app/Components/TaxSlabs`))
const Setnewpassword = React.lazy(() =>
  import(`./app/Components/Login/setnewpassword`),
)

const Gallery = React.lazy(() => import(`./app/Components/Gallery/index`))
const Jobs = React.lazy(() => import(`./app/Pages/Jobs`))
const JobDetails = React.lazy(() => import(`./app/Components/Jobs/JobDetails`))
const UpdateJob = React.lazy(() => import(`./app/Components/Jobs/UpdateJob`))
const AddJob = React.lazy(() => import(`./app/Components/Jobs/AddJob`))
const TeamDetails = React.lazy(() =>
  import(`./app/Components/Teams/TeamDetails`),
)

const AllAccountsPage = React.lazy(() => import(`./app/Pages/Accounts`))

import { RoleGuard } from 'app/Services'
import ViewExpense from 'app/Components/Accounts/ViewExpense'
import AccountDetail from 'app/Components/Accounts/AccountDetail'
import Reports from 'app/Components/Accounts/Reports'
import GenerateReports from 'app/Components/Accounts/Reports/GenerateReports'
import ExpenseReports from 'app/Components/Expenses/ExpenseReports'
import Funds from 'app/Components/Funds'
import FundDetail from 'app/Components/Funds/FundDetail'
const QRCodeGenerator = React.lazy(() => import(`./app/Components/QRCode`))
const Expenses = React.lazy(() => import(`app/Components/Expenses`))
import ExpenditureDashboard from 'app/Components/Expenses/ExpenditureDashboard'

import Clients from 'app/Components/Income/Clients'
import Vendors from 'app/Components/Income/Vendors'
import Income from 'app/Components/Income'
import AttendanceByDate from 'app/Components/AttendanceByDate'
import BusinessDeveloper from 'app/Components/BusinessDeveloperApplicant'
import ApplicantDetail from 'app/Components/BusinessDeveloperApplicant/ApplicantDetail'
import { getRole } from './app/Utils/helper'

const AddInvoice = React.lazy(() =>
  import(`./app/Components/Income/Invoices/AddInvoice`),
)
const SingleInvoice = React.lazy(() =>
  import(`./app/Components/Income/Invoices/SingleInvoice`),
)
const UpdateInvoice = React.lazy(() =>
  import(`./app/Components/Income/Invoices/UpdateInvoice`),
)

const Assessment = React.lazy(() => import(`./app/Components/Assessment`))

const AddAssessment = React.lazy(() =>
  import(`./app/Components/Assessment/AddBulkAssessment`),
)

const ViewAssessment = React.lazy(() =>
  import(`./app/Components/Assessment/ViewAssessment/index`),
)
const UpdateAssessment = React.lazy(() =>
  import(`./app/Components/Assessment/UpdateAssessment`),
)

const MySingleAssessment = React.lazy(() =>
  import(`./app/Components/Assessment/MyAssessments`),
)

const ApplicationForm = React.lazy(() =>
  import(`./app/Components/Assessment/BusinessDeveloperForm/index`),
)

const QrCodeInfo = React.lazy(() => import(`./app/Components/QRCode/InfoLink`))
const LoanReport = React.lazy(() => import(`./app/Components/LoanReport`))

//$ ================================================================================ $//
//$                                 CONTRACTS
//$ ================================================================================ $//

const AllContracts = React.lazy(() =>
  import(`./app/Components/Contracts/AllContracts/index`),
)
const ContractDetails = React.lazy(() =>
  import(`./app/Components/Contracts/ContractDetails/index`),
)

const AddContract = React.lazy(() =>
  import(`./app/Components/Contracts/AddContract/index`),
)

const PageNotFound = React.lazy(() => import(`./app/Components/PageNotFound`))

const App = () => {
  const colors = useSelector((state) => state.colors)
  const queryClient = new QueryClient()
  const systemRole = getRole()

  const FallbackValue = () => {
    return (
      <Box
        sx={{
          minWidth: `100vw`,
          minHeight: `100vh`,
          backgroundColor: `grey`,
        }}
      >
        <CircularProgress
          size={50}
          sx={{
            position: `absolute`,
            left: `50%`,
            top: `50%`,
            transform: `translateY(-50%) translateX(-50%)`,
            color: colors?.appColor,
          }}
        />
      </Box>
    )
  }

  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Switch>
            <Route exact path="/">
              <ProtectedLogin>
                <Suspense fallback={FallbackValue()}>
                  <Login />
                </Suspense>
              </ProtectedLogin>
            </Route>

            <Route exact path="/resetPassword/:token">
              <Suspense fallback={FallbackValue()}>
                <Setnewpassword />
              </Suspense>
            </Route>

            <Route exact path="/dashboard">
              <ProtectedRoutes>
                <Suspense fallback={FallbackValue()}>
                  <Dashboard />
                </Suspense>
              </ProtectedRoutes>
            </Route>

            <Route exact path="/employees">
              <ProtectedRoutes>
                <Suspense fallback={FallbackValue()}>
                  <AllEmployees />
                </Suspense>
              </ProtectedRoutes>
            </Route>

            <Route exact path="/payroll">
              <ProtectedRoutes>
                <Suspense fallback={FallbackValue()}>
                  <Payroll />
                </Suspense>
              </ProtectedRoutes>
            </Route>

            <Route exact path="/taxDetails">
              <ProtectedRoutes>
                <Suspense fallback={FallbackValue()}>
                  <TaxDetails />
                </Suspense>
              </ProtectedRoutes>
            </Route>

            <Route exact path="/taxSlabs">
              <ProtectedRoutes>
                <Suspense fallback={FallbackValue()}>
                  <TaxSlabs />
                </Suspense>
              </ProtectedRoutes>
            </Route>

            <Route exact path="/requests">
              <ProtectedRoutes>
                <Suspense fallback={FallbackValue()}>
                  <Requests />
                </Suspense>
              </ProtectedRoutes>
            </Route>

            <Route exact path="/attendance">
              <ProtectedRoutes>
                <Suspense fallback={FallbackValue()}>
                  <AllAttendance />
                </Suspense>
              </ProtectedRoutes>
            </Route>

            <Route exact path="/payroll/payrollSlip/:userId/:payrollId">
              <ProtectedRoutes>
                <Suspense fallback={FallbackValue()}>
                  <PayrollSlip />
                </Suspense>
              </ProtectedRoutes>
            </Route>

            <Route exact path="/payroll/payrollAnalytics">
              <ProtectedRoutes>
                <Suspense fallback={FallbackValue()}>
                  <PayrollAnalytics />
                </Suspense>
              </ProtectedRoutes>
            </Route>

            <Route
              exact
              path="/attendance/attendanceIndividual/:paramsId/:paramsName"
            >
              <ProtectedRoutes>
                <Suspense fallback={FallbackValue()}>
                  <IndividualsAttendance />
                </Suspense>
              </ProtectedRoutes>
            </Route>

            <Route exact path="/users">
              <ProtectedRoutes>
                <Suspense fallback={FallbackValue()}>
                  <AllUsers />
                </Suspense>
              </ProtectedRoutes>
            </Route>

            <Route exact path="/users/addNewUser">
              <ProtectedRoutes>
                <Suspense fallback={FallbackValue()}>
                  <AddNewUser />
                </Suspense>
              </ProtectedRoutes>
            </Route>

            <Route exact path="/users/userDetail/:id">
              <ProtectedRoutes>
                <Suspense fallback={FallbackValue()}>
                  <CompleteDetail />
                </Suspense>
              </ProtectedRoutes>
            </Route>

            <Route exact path="/salary">
              <ProtectedRoutes>
                <Suspense fallback={FallbackValue()}>
                  <AddSalary />
                </Suspense>
              </ProtectedRoutes>
            </Route>

            <Route exact path="/employeeDetails/:id">
              <ProtectedRoutes>
                <Suspense fallback={FallbackValue()}>
                  <EmployeeDetail />
                </Suspense>
              </ProtectedRoutes>
            </Route>

            <Route exact path="/departments">
              <ProtectedRoutes>
                <Suspense fallback={FallbackValue()}>
                  <Departments />
                </Suspense>
              </ProtectedRoutes>
            </Route>

            <Route exact path="/teams">
              <ProtectedRoutes>
                <Suspense fallback={FallbackValue()}>
                  <Teams />
                </Suspense>
              </ProtectedRoutes>
            </Route>

            <Route exact path="/teams/:teamId">
              <ProtectedRoutes>
                <Suspense fallback={FallbackValue()}>
                  <TeamDetails />
                </Suspense>
              </ProtectedRoutes>
            </Route>

            <Route exact path="/positions">
              <ProtectedRoutes>
                <Suspense fallback={FallbackValue()}>
                  <Positions />
                </Suspense>
              </ProtectedRoutes>
            </Route>

            <Route exact path="/designations">
              <ProtectedRoutes>
                <Suspense fallback={FallbackValue()}>
                  <Designation />
                </Suspense>
              </ProtectedRoutes>
            </Route>

            <Route exact path="/projects">
              <ProtectedRoutes>
                <Suspense fallback={FallbackValue()}>
                  <CompanyProjects />
                </Suspense>
              </ProtectedRoutes>
            </Route>

            {/* //* ********************************  CONTRACTS ROUTES  ********************************* */}

            {/* //* All Contracts  */}
            <Route exact path="/contracts">
              <RoleGuard
                userRole={systemRole}
                allowedRoles={[`HRM`, `CEO`, `CTO`]}
              >
                <ProtectedRoutes>
                  <Suspense fallback={FallbackValue()}>
                    <AllContracts />
                  </Suspense>
                </ProtectedRoutes>
              </RoleGuard>
            </Route>

            {/* //* Single Contract  */}
            <Route exact path="/contracts/:contractId">
              <RoleGuard
                userRole={systemRole}
                allowedRoles={[`HRM`, `CEO`, `CTO`]}
              >
                <ProtectedRoutes>
                  <Suspense fallback={FallbackValue()}>
                    <ContractDetails />
                  </Suspense>
                </ProtectedRoutes>
              </RoleGuard>
            </Route>

            {/* //* Add Contracts  */}
            <Route exact path="/contracts/:userId/add">
              <RoleGuard
                userRole={systemRole}
                allowedRoles={[`HRM`, `CEO`, `CTO`]}
              >
                <ProtectedRoutes>
                  <Suspense fallback={FallbackValue()}>
                    <AddContract />
                  </Suspense>
                </ProtectedRoutes>
              </RoleGuard>
            </Route>

            {/* //? ************************************************************************************* */}

            <Route exact path="/halfdays">
              <ProtectedRoutes>
                <Suspense fallback={FallbackValue()}>
                  <HalfDays />
                </Suspense>
              </ProtectedRoutes>
            </Route>

            <Route exact path="/holidays">
              <ProtectedRoutes>
                <Suspense fallback={FallbackValue()}>
                  <Holidays />
                </Suspense>
              </ProtectedRoutes>
            </Route>

            <Route exact path="/shifts">
              <ProtectedRoutes>
                <Suspense fallback={FallbackValue()}>
                  <Shifts />
                </Suspense>
              </ProtectedRoutes>
            </Route>

            <Route exact path="/configuration">
              <ProtectedRoutes>
                <Suspense fallback={FallbackValue()}>
                  <Configuration />
                </Suspense>
              </ProtectedRoutes>
            </Route>

            <Route exact path="/quotations">
              <ProtectedRoutes>
                <Suspense fallback={FallbackValue()}>
                  <Quotations />
                </Suspense>
              </ProtectedRoutes>
            </Route>

            <Route exact path="/gallery">
              <ProtectedRoutes>
                <Suspense fallback={FallbackValue()}>
                  <Gallery />
                </Suspense>
              </ProtectedRoutes>
            </Route>

            <Route exact path="/jobs">
              <ProtectedRoutes>
                <Suspense fallback={FallbackValue()}>
                  <Jobs />
                </Suspense>
              </ProtectedRoutes>
            </Route>

            <Route exact path="/jobs/update/:jobId">
              <ProtectedRoutes>
                <Suspense fallback={FallbackValue()}>
                  <UpdateJob />
                </Suspense>
              </ProtectedRoutes>
            </Route>
            <Route exact path="/jobs/add">
              <ProtectedRoutes>
                <Suspense fallback={FallbackValue()}>
                  <AddJob />
                </Suspense>
              </ProtectedRoutes>
            </Route>
            <Route exact path="/jobs/details/:jobId">
              <ProtectedRoutes>
                <Suspense fallback={FallbackValue()}>
                  <JobDetails />
                </Suspense>
              </ProtectedRoutes>
            </Route>

            {/* //* Applicants Routes */}
            <Route exact path="/applicants">
              <ProtectedRoutes>
                <Suspense fallback={FallbackValue()}>
                  <AllApplicants />
                </Suspense>
              </ProtectedRoutes>
            </Route>

            <Route exact path="/applicants/:applicantId">
              <ProtectedRoutes>
                <Suspense fallback={FallbackValue()}>
                  <ApplicantDetails />
                </Suspense>
              </ProtectedRoutes>
            </Route>

            {/* //**************************************  */}

            <Route exact path="/businessDeveloperApplicant">
              <ProtectedRoutes>
                <Suspense fallback={FallbackValue()}>
                  <BusinessDeveloper />
                </Suspense>
              </ProtectedRoutes>
            </Route>
            <Route
              exact
              path="/businessDeveloperApplicant/ApplicationDetail/:id"
            >
              <ProtectedRoutes>
                <Suspense fallback={FallbackValue()}>
                  <ApplicantDetail />
                </Suspense>
              </ProtectedRoutes>
            </Route>

            <Route exact path="/QRCode">
              <ProtectedRoutes>
                <Suspense fallback={FallbackValue()}>
                  <QRCodeGenerator />
                </Suspense>
              </ProtectedRoutes>
            </Route>
            <Route exact path="/QRCodeInfo/:id">
              <Suspense fallback={FallbackValue()}>
                <QrCodeInfo />
              </Suspense>
            </Route>
            <Route exact path="/accounts">
              <RoleGuard
                userRole={systemRole}
                allowedRoles={[`HRM`, `CEO`, `CTO`]}
              >
                <ProtectedRoutes>
                  <Suspense fallback={FallbackValue()}>
                    <AllAccountsPage />
                  </Suspense>
                </ProtectedRoutes>
              </RoleGuard>
            </Route>
            <Route exact path="/accounts/budgetReports">
              <RoleGuard
                userRole={systemRole}
                allowedRoles={[`HRM`, `CEO`, `CTO`]}
              >
                <ProtectedRoutes>
                  <Reports />
                </ProtectedRoutes>
              </RoleGuard>
            </Route>
            <Route exact path="/accounts/budgetReports/generateReports">
              <RoleGuard
                userRole={systemRole}
                allowedRoles={[`HRM`, `CEO`, `CTO`]}
              >
                <ProtectedRoutes>
                  <GenerateReports />
                </ProtectedRoutes>
              </RoleGuard>
            </Route>

            <Route exact path="/accounts/:accountId">
              <RoleGuard
                userRole={systemRole}
                allowedRoles={[`HRM`, `CEO`, `CTO`]}
              >
                <ProtectedRoutes>
                  <AccountDetail />
                </ProtectedRoutes>
              </RoleGuard>
            </Route>
            <Route exact path="/accounts/:id/budget/:budgetId">
              <RoleGuard
                userRole={systemRole}
                allowedRoles={[`HRM`, `CEO`, `CTO`]}
              >
                <ProtectedRoutes>
                  <ViewExpense />
                </ProtectedRoutes>
              </RoleGuard>
            </Route>

            <Route exact path="/expenses">
              <RoleGuard
                userRole={systemRole}
                allowedRoles={[`HRM`, `CEO`, `CTO`]}
              >
                <ProtectedRoutes>
                  <Suspense fallback={FallbackValue}>
                    <Expenses />
                  </Suspense>
                </ProtectedRoutes>
              </RoleGuard>
            </Route>
            <Route exact path="/expenses/expenseReports">
              <RoleGuard
                userRole={systemRole}
                allowedRoles={[`HRM`, `CEO`, `CTO`]}
              >
                <ProtectedRoutes>
                  <ExpenseReports />
                </ProtectedRoutes>
              </RoleGuard>
            </Route>
            <Route exact path="/funds">
              <RoleGuard
                userRole={systemRole}
                allowedRoles={[`HRM`, `CEO`, `CTO`]}
              >
                <ProtectedRoutes>
                  <Funds />
                </ProtectedRoutes>
              </RoleGuard>
            </Route>
            <Route exact path="/funds/:fundsId">
              <RoleGuard
                userRole={systemRole}
                allowedRoles={[`HRM`, `CEO`, `CTO`]}
              >
                <ProtectedRoutes>
                  <FundDetail />
                </ProtectedRoutes>
              </RoleGuard>
            </Route>
            <Route exact path="/ExpenditureAnalytics">
              <RoleGuard
                userRole={systemRole}
                allowedRoles={[`HRM`, `CEO`, `CTO`]}
              >
                <ProtectedRoutes>
                  <ExpenditureDashboard />
                </ProtectedRoutes>
              </RoleGuard>
            </Route>

            <Route exact path="/clients">
              <RoleGuard userRole={systemRole} allowedRoles={[`CEO`]}>
                <ProtectedRoutes>
                  <Clients />
                </ProtectedRoutes>
              </RoleGuard>
            </Route>

            <Route exact path="/business">
              <RoleGuard userRole={systemRole} allowedRoles={[`CEO`]}>
                <ProtectedRoutes>
                  <Vendors />
                </ProtectedRoutes>
              </RoleGuard>
            </Route>

            <Route exact path="/income">
              <RoleGuard userRole={systemRole} allowedRoles={[`CEO`]}>
                <ProtectedRoutes>
                  <Income />
                </ProtectedRoutes>
              </RoleGuard>
            </Route>

            <Route exact path="/income/addInvoice">
              <RoleGuard userRole={systemRole} allowedRoles={[`CEO`]}>
                <ProtectedRoutes>
                  <Suspense fallback={FallbackValue()}>
                    <AddInvoice />
                  </Suspense>
                </ProtectedRoutes>
              </RoleGuard>
            </Route>
            <Route exact path="/income/:invoiceId">
              <RoleGuard userRole={systemRole} allowedRoles={[`CEO`]}>
                <ProtectedRoutes>
                  <Suspense fallback={FallbackValue()}>
                    <SingleInvoice />
                  </Suspense>
                </ProtectedRoutes>
              </RoleGuard>
            </Route>
            <Route exact path="/income/:invoiceId/updateInvoice">
              <RoleGuard userRole={systemRole} allowedRoles={[`CEO`]}>
                <ProtectedRoutes>
                  <Suspense fallback={FallbackValue()}>
                    <UpdateInvoice />
                  </Suspense>
                </ProtectedRoutes>
              </RoleGuard>
            </Route>

            <Route path="/attendanceByDate">
              <ProtectedRoutes>
                <Suspense fallback={FallbackValue()}>
                  <AttendanceByDate />
                </Suspense>
              </ProtectedRoutes>
            </Route>

            <Route exact path="/assessments">
              <ProtectedRoutes>
                <Suspense fallback={FallbackValue()}>
                  <Assessment />
                </Suspense>
              </ProtectedRoutes>
            </Route>

            <Route exact path="/assessments/addbulk">
              <ProtectedRoutes>
                <Suspense fallback={FallbackValue()}>
                  <AddAssessment />
                </Suspense>
              </ProtectedRoutes>
            </Route>
            <Route exact path="/assessments/multipleAssessments/:assessmentId">
              <ProtectedRoutes>
                <Suspense fallback={FallbackValue()}>
                  <ViewAssessment />
                </Suspense>
              </ProtectedRoutes>
            </Route>

            <Route exact path="/assessments/update/:assessmentId">
              <ProtectedRoutes>
                <Suspense fallback={FallbackValue()}>
                  <UpdateAssessment />
                </Suspense>
              </ProtectedRoutes>
            </Route>
            <Route exact path="/assessments/:myAssessmentId">
              <ProtectedRoutes>
                <Suspense fallback={FallbackValue()}>
                  <MySingleAssessment />
                </Suspense>
              </ProtectedRoutes>
            </Route>

            <Route exact path="/businessDeveloperForm">
              <Suspense fallback={FallbackValue()}>
                <ApplicationForm />
              </Suspense>
            </Route>

            <Route path="/loanReport">
              <ProtectedRoutes>
                <Suspense fallback={FallbackValue()}>
                  <LoanReport />
                </Suspense>
              </ProtectedRoutes>
            </Route>

            <Route path="*">
              <Suspense fallback={FallbackValue()}>
                <PageNotFound />
              </Suspense>
            </Route>
          </Switch>
        </BrowserRouter>
      </QueryClientProvider>
    </Provider>
  )
}

export default App
